.MeetingLinks {
  border-radius: 15px;
  background: #018385;
  max-width: 300px;
  padding: 20px;
  margin: 0;
}
.MeetingLinks .MuiAvatar-circular {
  background: #96cbcc;
  margin-right: 10px; 
}
.MessageArea .rightMessage .MeetingLinks .MuiTypography-p {
  background: #018385;padding: 0; align-self: center;
}
.MessageArea .leftMessage  .MeetingLinks .MuiTypography-p { border-radius: 0; background: none; padding: 0;}

.MeetingLinkTxt {
  display: flex;
  align-items: center;
  color: #fff;
  font-size: 16px;
  font-style: normal;
  font-weight: 600;
  line-height: normal; 
}

.MeetingLinks .LinkTxt {
  display: flex;
  align-items: center;
  color: #fff;
  font-size: 16px;
  font-style: normal;
  font-weight: 600;
  line-height: normal; 
}
.join-btn {
  border-radius: 25px;
  color: #333 !important;
  background-color: #fff;
  font-size: 10px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
  text-align: center;
  width: 100%;
  text-transform: none;
  padding: 6px;
  margin-top: 10px;
}
.join-btn:hover{ background: #fff;}
.join-btn:disabled{ background: #e8e8e8;}

.join-btn .MuiTouchRipple-root{ display: none;}

.acceptButton{
  border-radius: 25px;
  color: #333 !important;
  background-color: #fff;
  font-size: 10px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
  text-align: center;
  width: 48%;
  text-transform: none;
  padding: 6px;
  margin-top: 10px;
  margin-right: 2%;
}

.leftMessage .acceptButton span{
  background-color:transparent;
  border-radius: 25px;
}

.declineButton{
  border-radius: 25px;
  border: 1px solid white;
  color:white !important;
  padding-left: 2%;
  font-size: 10px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
  text-align: center;
  width: 48%;
  text-transform: none;
  padding: 6px;
  margin-top: 10px;
}
.leftMessage .declineButton span{
  background-color:transparent;
}
.Meeting {
  border-radius: 15px;
  background:  #018385;
  max-width: 300px;
  padding: 20px;
  margin: 0;
}
.Meeting .MuiAvatar-circular {
  background: #96cbcc;
  margin-right: 10px; 
}
.meetingTypo{
  background:  #018385;
}

.MessageArea .rightMessage .MeetingLinkTxt .MuiTypography-p{
  background:  #018385;
}
.MessageArea .leftMessage  .MeetingLinkTxt .MuiTypography-p { 
  background:  #018385;
}