.otpinput .MuiOutlinedInput-input {
  text-align: center;
}

#otp_component input[type="number"]::-webkit-inner-spin-button,
#otp_component input[type="number"]::-webkit-outer-spin-button {
  -webkit-appearance: none;
  appearance: none;
  margin: 0;
}
.ForgotPasswordBox {
  padding: 60px;
}
.ForgotPasswordBox .MuiTypography-h5 {
  font-size: 28px;
  font-weight: 700;
}
.ForgotPasswordBox .MuiTypography-h6 {
  color: #333;
  font-size: 18px;
  font-weight: 600;
  line-height: 138.523%;
  margin-bottom: 15px;
}

.ForgotPasswordBox .MuiTypography-p {
  color: #333 !important;
  margin-bottom: 10px;
  display: block;
}

.ForgotPasswordBox .css-1ebprri {
  margin-top: 38px;
  margin-bottom: 0;
  color: #333;
}

.resendCnt .MuiButton-root {
  text-transform: none;
  text-decoration: underline;
  font-family: "Nunito Sans", sans-serif !important;
  padding-left: 0;
  color: #9d9ea8;
  font-size: 16px;
  font-weight: 700;
}
.resendCnt a {
  color: #9d9ea8;
  font-size: 16px;
  font-weight: 700;
  text-decoration-line: underline;
  font-family: "Nunito Sans", sans-serif !important;
}
.resendCnt .MuiTypography-body1 {
  color: #9d9ea8;
  font-size: 16px;
  font-weight: 700;
}

.error-message {
  font-size: 15px;
  color: red;
}

@media (max-width: 900px) {
  .LoginPage .ForgotPasswordBox {
    padding: 15px;
  }
  .title-verification {
    font-size: 30px;
  }
  .desc-verification {
    font-size: 16px !important;
  }
  .verificationBox {
    padding-bottom: 0 !important;
  }
}
