.SignIn {  
  display: flex;
  flex-grow: 1;
  align-items: center;
  justify-content: center;
}

.SignIn .LeftTextCol{ padding-left: 75px;}
.SignIn .RightTextCol{ padding-right: 30px;}

.SignIn .MuiTypography-p{color: #696B71;}
.SignIn .MuiTypography-h5{ color: #202030; font-family: 'Bai Jamjuree', sans-serif !important; line-height: 116.1%; /* 92.88px */ max-width: 880px;}

.SignIn .css-1yuhvjn { margin-top: 38px; color: #333;}
.SignIn .MuiButton-contained{ padding: 13px 26px; border-radius: 40px; font-size: 20px; text-transform: none;}

.SignInBox .MuiGrid-item{ padding: 65px 75px;}

.SignInBox .css-1ebprri { margin-top: 10px; margin-bottom: 25px;}
.SignInBox .css-1ebprri a{ color: #9D9EA8; font-size: 14px; text-decoration: none;}

.newHere span{color: #333 !important; font-family: 'Nunito Sans', sans-serif !important; font-size: 16px !important; font-weight: 400;}
.newHere span a{ font-weight: 700 ;  color: #333;}

.SignIn .MuiDivider-root:before{ display: none;}
.SignIn .MuiDivider-root .MuiDivider-wrapper{ padding-left: 0; color: #6A7477;  font-size: 18px;}

@media (max-width: 900px) {
  .SignIn {
    .LeftTextCol, .RightTextCol { 
      padding: 0 20px;
    }
  }
  .LoginPage .SignIn{
    padding: 0 15px;
  }

  .SignInBox .MuiGrid-item{ padding: 30px 20px;}
}
@media (min-width: 901px){
  .SignIn.landing {
    height: calc(100svh - 172px);
  }
}