.LoginFooter {
  bottom: 0;
  width: calc(100% - 60px);
  padding: 30px;
  display: flex;
  align-items: center;
  justify-content: space-between;
}
@media (max-width:900px) {
  .LoginFooter {
    text-align: center;
    flex-direction: column;
  }
}
