.Feedback{ border-radius: 0 10px 10px 10px; background: #FFF; box-shadow: 0px 4px 4px 0px rgba(0, 0, 0, 0.05); padding: 20px 20px 20px; max-width: 60%; margin: 10px; font-size: 14px;}


.Feedback .MuiTypography-body1{ display: block; color: #414148; font-size: 16px; font-style: normal; font-weight: 600; line-height: normal; margin-bottom: 20px;}
.Feedback .MuiTypography-p{ font-size: 14px;}
.Feedback .MuiRating-root{ margin-top: 5px;}

/* 
.Feedback .MuiButton-contained{ background-color: #018385; border-radius: 20px; text-transform: none; font-weight: 600;} */

