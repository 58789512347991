
.ProjectName{ background: #C8CBFE; border-radius: 10px 10px 0 0;}
.ProjectName .MuiListItemText-primary{ font-size: 12px; color: #333; font-weight: bold; text-transform: uppercase;}
.ProjectName .MuiListItemText-secondary { font-size: 16px; color: #333 !important; font-weight: bold !important;}
.ProjectName .MuiIconButton-root{ position: relative; top: -13px; right: -8px;}

@media (max-width:900px) {
    .ProjectName{
        border-radius: 15px;
    }
    .ProjectName .MuiListItemText-secondary { font-size: 14px;}
}