
.SeeProfileCnt{ 
    height: calc(100svh - 120px);
    background: #fff;
    overflow: auto;
    overflow-x: hidden; border-radius: 10px; color: #5E5F69; font-size: 14px;
}
.SeeProfileCnt .MuiTypography-title{color: #5E5F69; font-size: 14px; font-weight: 600; display: block; margin-bottom: 10px; text-transform: uppercase;}
.SeeProfileCnt .MuiStack-root{ flex-wrap: wrap;}
.SeeProfileCnt .MuiChip-root { margin: 0 5px 5px 0; border-radius: 20px;  background: #E9CDFF; font-size: 12px; font-weight: 400; padding: 5px 10px;}
.SeeProfileCnt .MuiChip-root span{ padding: 0;}
.activeProject .SeeProfileCnt .MuiChip-root { margin: 0 5px 5px 0; border-radius: 20px;  background: #38B54D; font-size: 50px; font-weight: 400; padding: 5px 10px;}


.SeeProfileCnt .MuiTableContainer-root{ box-shadow: none; border-radius: 0;}
.SeeProfileCnt .MuiTableCell-body{ padding: 5px 0; border-bottom: 0;}

.SeeProfileCnt .MuiAvatar-root{ width: 87px; height: 87px;}
.SeeProfileCnt .MuiTypography-name{ color: #333; font-size: 16px; font-weight: 700; text-transform: uppercase; text-align: center; margin-top: 15px; display: block;}

.SeeProfileCnt .MuiRating-root{ font-size: 1rem;}
.SeeProfileCnt .css-d0uhtl{ font-size: 12px; font-weight: 600; margin-left: 5px;}

.pBox{ padding: 20px;}
.pBox .MuiTypography-span{font-weight: 400;}