

.PriceSummary{ background: #fff; padding: 16px; border-radius: 10px 10px 0 0; height: calc(100svh - 222px);}
.PriceSummary .ProjectName{ border-radius: 15px; margin-bottom: 15px;}
.PriceSummary .ProjectName .css-1mcnwpj-MuiList-root{ background: none;}

.PriceSummary .ProjectName .MuiIconButton-root{ display: none;}

.PriceSummary .ProjectName .css-1p823my-MuiListItem-root{ padding-top:0; padding-bottom: 0;}

.PriceSummary .MuiTypography-subtitle1{ margin-bottom: 0px; color: #333; font-size: 16px; font-weight: 600;}


.PriceSummary .MuiTableContainer-root{ box-shadow: none;}
.PriceSummary .css-1ex1afd-MuiTableCell-root{ padding: 0;}
.PriceSummary .MuiTableCell-body p{ display: flex; justify-content: space-between;}
.PriceSummary .MuiTableCell-body.css-177gid-MuiTableCell-root{ padding:0;}

.MuiTypography-subtitle2 span{ display: block; font-weight: normal;}

