.Signup {  
  display: flex;
  flex-grow: 1;
  align-items: center;
  justify-content: center;
}


.SignUpBox .MuiGrid-item{ padding: 65px;}
.SignUpBox .MuiTypography-h3{ color: #202030; font-family: 'Bai Jamjuree', sans-serif !important; font-weight: 700; margin-bottom: 10px;}
.SignUpBox .MuiTypography-p{ color: #333; font-size: 20px; font-weight: 400; line-height: 145%; /* 29px */}
.SignUpBox .MuiTypography-p span{ font-weight: 700; color: #018385; font-style: italic;}

.SignUpBox .MuiTypography-h5{ color: #333; font-size: 24px; font-weight: 700; line-height: normal; padding-top: 30px;}

.SignUpBox .MuiCheckbox-root{ padding: 10px 10px 10px 0;}
.SignUpBox .css-1yuhvjn { margin-top: 20px; color: #333;}

.SignUpBox .MuiDivider-root:before{ display: none;}
.SignUpBox .MuiDivider-root .MuiDivider-wrapper{ padding-left: 0; color: #6A7477;  font-size: 18px;}

.cancelIcon{
  background-color:red;
  border-radius: 50%;
  color: #fff;
}
.checkIcon{
  background-color:green;
  border-radius: 50%;
  color: #fff;
}
.passwordRowBox{
  display: flex; 
  flex-direction: row;
  padding-bottom: 10px;
}
.passwordColumnBox{
  display: flex;
  flex-direction: column;
  padding-top: 10px;
  padding-left:10px;
}

.passwordTypo{
  padding-left: 10px;
  /* padding-top: 10px; */
}

@media (max-width: 900px) {
  .Signup {
    .LeftTextCol, .RightTextCol { 
      padding: 0 20px;
    }
  }
  .LoginPage .Signup{
    padding: 15px;
  }
  .SignUpBox .MuiGrid-item{ padding: 30px 20px;}
  .SignUpBox .MuiGrid-item.title-new{ padding-bottom: 0;}
  .SignUpBox .MuiGrid-item.title-create{ padding-top: 0;}
}

@media (max-width: 900px) {
  .font-80.mob-xs { font-size: 20px; }
}