.searchContainer {
  align-items: center;
  background-color: white;
  border-radius: 4px;
  margin: 20px;
  border-bottom: 1px solid #eeeeee;
}

.searchIcon,
.filterIcons {
  display: flex; /* Ensure that the icons are displayed horizontally */
  align-items: center;
  padding: 8px; /* Added padding for spacing */
}
.filterIcons {
  display: flex;
  align-items: center;
  margin-left: auto; /* Push the filter icons to the right end */
  padding: 8px;
}
.input {
  border: none;
  outline: none;
}
.disable-hover-effect {
  border-radius: 30px;
  background-color: #e4e4e4;
  color: #333333;
  &:hover {
    background-color: #e4e4e4;
  }
}
.file-icon {
  position: absolute;
  font-size: 10px;
  top: 4px;
  left: 7px;
  color: white;
  font-weight: 700;
}

.filesTableRow {
  display: grid;
  grid-template-columns: repeat(4, 1fr);
  border: 1px solid rgba(145, 158, 171, 0.16);
  margin: 5px;
  border-radius: 10px;
}

.centerTable {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100%;
  width: 95%;
  margin: auto;
}

@media screen and (max-width: 550px) {
  .css-1q1u3t4-MuiTableRow-root {
    grid-template-columns: repeat(2, 1fr);
  }
  .MuiTableCell-sizeMedium:nth-child(2) {
    display: none !important;
  }
  .MuiTableCell-sizeMedium:nth-child(3) {
    display: flex;
    justify-content: flex-end;
  }
}
