.dot-menu .MuiMenu-paper {
  left: 8px !important;
  width: 388px !important;
  bottom: 130px !important;
  height: auto;
  top: auto !important;
  max-height: 300px !important;
}

.call-menu .MuiMenu-paper {
  left: 78% !important;
  width: 280px !important;
  bottom: 60px !important;
  height: auto;
  top: auto !important;
  max-height: 300px !important;
}

/* .customMenuu {
  left: 8px !important;
  width: 388px !important;
  bottom: 130px !important;
  height: auto;
  top: auto !important;
  max-height: 300px !important;
} */
.menu-item-content svg {
  color: gray;
  margin-bottom: -7px;
}
@media (max-width: 900px) {
  .dot-menu .MuiMenu-paper {
    right: 8px !important;
    width: 388px !important;
    bottom: 65px !important;
    height: auto;
    top: auto !important;
    left: auto !important;
    max-height: 300px !important;
  }
  .call-menu .MuiMenu-paper {
    right: 8px !important;
    width: 388px !important;
    bottom: 65px !important;
    height: auto;
    top: auto !important;
    left: auto !important;
    max-height: 300px !important;
  }
}
