.t-success{
    background-color: #199d74;
    color:#fff
}
.t-failed{
    background-color: #e53737;
    color: #fff
}

/* .pdf-wallet, .pdf-job{
        width: 21cm;
        min-height: 29.7cm;
        padding: 2cm;
        margin: 1cm auto;
        border: 1px #D3D3D3 solid;
        border-radius: 5px;
        background: white;
        box-shadow: 0 0 5px rgba(0, 0, 0, 0.1);
} */

.pdf-main {
    flex-grow: 1;
    margin: 20px;
    display: flex;
    flex-direction: column;
    min-height: 75vh;
    font-family: Arial, Helvetica, sans-serif;
  }

  .pdf-table {
    border-collapse: collapse;
    width: calc(100% - 10px);
    margin: 5px;
  }

  .pdf-th,
  .pdf-td {
    padding: 8px;
    text-align: left;
  }

  .pdf-th {
    border-radius: var(--none, 0px);
    background: #f6f6f6;
  }

  .pdf-td {
    border-bottom: 1px solid #ddd;
  }

  .subtotal-row {
    background: #f6f6f6;
  }

  .subtotal-row td:first-child,
  .subtotal-row td:nth-child(2),
  .subtotal-row td:nth-child(3),
  .subtotal-row td:nth-child(4),
  .subtotal-row td:nth-child(5) {
    border-bottom: none;
    background-color: white;
  }

  .subtotal-row th:last-child,
  .subtotal-row th:nth-child(6) {
    border-bottom: 1px solid #ddd;
    padding: 10px;
  }

  .pdf-footer {
    background: linear-gradient(126deg, #fbfcdb 0%, #def3fa 98.93%);
    padding: 15px;
    margin-top: auto;
  }

  .footer-div {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: inherit
  }

  .pdf-h1 {
    margin: 0;
    font-family: Nunito Sans;
    line-height: normal;
  }

  .pdf-hr {
    height: 1px;
    border: none;
    background-color: #000;
    width: 100%;
  }

  .pdf-header {
    height: 6px;
    flex-shrink: 0;
    border-radius: var(--none, 0px);
    background: linear-gradient(0deg, #018385 0%, #018385 100%),
      linear-gradient(126deg, #fbfcdb 0%, #def3fa 98.93%);
  }