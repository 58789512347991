
.UserStatus{ position: absolute; top: -100px; border-radius: 10px; background: #fff; box-shadow: 0px 4px 10px 0px rgba(0, 0, 0, 0.4); width: 100%; z-index: 1;}

.UserStatus a{ display: flex; text-decoration: none; padding: 6px; border-bottom: 1px solid #ddd;}
.UserStatus a img{ width: 13px; height: 13px;}
.UserStatus a:last-child{ border-bottom: 0;}
.MuiTypography-body1{ font-weight: 400; font-size: 13px;}

.UserStatus .MuiAvatar-root{ width: auto; height: auto; margin-right: 0; margin-left: 10px;}
@media (max-width:900px) {
    .UserStatus {
        width: 200px;
        left: 1%;
    }
}