.profilepage {
  background: white;
  min-height: calc(100svh - 86px);
  width: 100%;
  margin: 8px 8px 8px 0;
  border-radius: 10px;

  .MyCardContainer.profile {
    height: calc(100svh - 84px);
  }
}

.JobPagepage {
  background: white;
  min-height: calc(100svh - 96px);
  width: 98%;
  margin: 8px 8px 8px 0;
  border-radius: 10px;

  .MyCardContainer.profile {
    height: calc(100svh - 84px);
  }
}
.size-82 {
  height: 82px;
  width: 82px;
}
.mob_profile_btn {
  display: none;
}
@media (max-width: 900px) {
  .mob_profile_btn {
    display: flex;
  }
  .profilepage {
    width: 100%;
    height: 100%;
    height: 100vh;
    overflow-y: auto;
    position: fixed;
    top: 0;
    left: 0;
    margin: 0;
    z-index: 1;

    .MyCardContainer.profile {
      height: calc(100svh - 82px);
    }
  }
  .size-82 {
    height: 50px;
    width: 50px;
  }
}

.skillsBox .css-13cymwt-control {
  height: 56px;
}

.selectBorder {
  border-radius: 5px;
  border: solid 1px #d32f2f;
}

.user_profile_label {
  cursor: pointer;
  position: relative;
  display: block;
  width: fit-content;
}
.user_profile_img {
  height: 80px;
  width: 80px;
  overflow: hidden;
  border-radius: 50%;
  -webkit-border-radius: 50%;
  -moz-border-radius: 50%;
  -ms-border-radius: 50%;
  -o-border-radius: 50%;
}
.profile_img_btn_container {
  display: flex;
  gap: 1em;
  margin-top: 2em;
}
#cancel_btn {
  background-color: gray;
}
/* .ReactCrop__crop-selection {
  border: 2px solid black;
} */
.ReactCrop__child-wrapper img {
  width: 100%;
  height: 530px;
}
