.LoginHeader {
  padding: 30px;
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.LoginHeader .signup {
  background-color: #fdf64a;
  border-radius: 20px;
  box-shadow: none;
}
.LoginHeader Button {
  color: #333333;
  font-size: 16px; text-transform: none; margin-left: 10px
}
