.Rightsidebar {
  /*width: 320px;
  */
  min-height: calc(100svh - 76px);
  margin: 8px;
  border-radius: 10px;
  position: relative;
  /*background: #fff;
  */

  transition: all 0.4s ease-in-out;
}
.Rightsidebar.show {
  transform: translateX(10px);
  transition: all 0.4s ease-in-out;
}
.Rightsidebar.hide {
  transform: translateX(110%);
  transition: all 0.4s ease-in-out;
}

.Rightsidebar .sellerTab {
  text-transform: none;
  font-size: 12px;
  font-weight: bold;
  color: #5e5f69;
  width: 33.33%;
  min-width: auto !important;
  padding: 15px 5px !important;
  min-height: 36px;
}

.hideRightSideBar {
  background-color: red !important;
}

.Rightsidebar .buyerTab {
  text-transform: none;
  font-size: 12px;
  font-weight: bold;
  color: #5e5f69;
  /* width: 24.33%; */
  min-width: 70px !important;
  padding: 15px 5px !important;
  min-height: 36px;
}

.Rightsidebar .MuiTab-root.Mui-selected {
  color: #333333;
  font-weight: bold;
  background: white;
  border-top-left-radius: 10px;
  border-top-right-radius: 10px;
}

.Rightsidebar .MuiTabPanel-root {
  padding: 0px;
  margin-top: -3px;
  position: relative;
}

.Rightsidebar .css-1gsv261 {
  border: 0;
}
.Rightsidebar .css-1ujnqem-MuiTabs-root {
  background: #f3f5fb;
  min-height: auto;
  flex-grow: 1;
}
.Rightsidebar .css-1aquho2-MuiTabs-indicator {
  display: none;
}

.superHeader .MuiTab-root.Mui-selected {
  color: #333333;
  font-weight: bold;
  background: white;
  border-top-left-radius: 10px;
  border-top-right-radius: 10px;
}

.superHeader .MuiTabPanel-root {
  padding: 0px;
  margin-top: -3px;
  position: relative;
}

.superHeader .css-1gsv261 {
  border: 0;
}
.superHeader .css-1ujnqem-MuiTabs-root {
  background: #f3f5fb;
  min-height: auto;
  flex-grow: 1;
}
.superHeader .css-1aquho2-MuiTabs-indicator {
  display: none;
}
.BorderTab {
  border-radius: 0 15px 0 0 !important;
  -webkit-border-radius: 0 15px 0 0 !important;
  -moz-border-radius: 0 15px 0 0 !important;
  -ms-border-radius: 0 15px 0 0 !important;
  -o-border-radius: 0 15px 0 0 !important;
}
.right_nav_back_btn {
  background-color: white;
  border-right: 1px solid #eeeeee;
  border-radius: 15px 0 0 15px;
  -webkit-border-radius: 15px 0 0 15px;
  -moz-border-radius: 15px 0 0 15px;
  -ms-border-radius: 15px 0 0 15px;
  -o-border-radius: 15px 0 0 15px;
}

@media (max-width: 900px) {
  .Rightsidebar {
    width: 100%;
    height: 100%;
    position: fixed;
    top: 0;
    left: 0;
    margin: 0;
    background-color: #fff;
    border-radius: 0;
    z-index: 1;
  }
  .rightsidebar-header {
    background-color: #fff;
    height: 56px;
    box-shadow: 0px 4px 10px 0px #00000014;
    z-index: 1;
    position: relative;
  }
  .Rightsidebar .MuiTab-root {
    height: 56px;
  }
  .Rightsidebar .css-1ujnqem-MuiTabs-root {
    background-color: #fff;
  }
  .Rightsidebar .css-1aquho2-MuiTabs-indicator {
    display: block;
  }
  .right_nav_back_btn {
    display: none;
  }
}
