.ConfirmationPopup .MuiTypography-body1 {
    color: #5E5F69;
    font-size: 16px;
    font-weight: 400;
}

.ConfirmationPopup .MuiDialog-paperScrollPaper{ max-width: 430px;}

.ConfirmationPopup .MuiDialogTitle-root{ padding: 30px 30px 15px ;}
.ConfirmationPopup .MuiDialogContent-root{padding: 0 30px 25px ;}
.ConfirmationPopup .MuiDialogActions-root{padding: 0 30px 30px ;}

.ConfirmationPopup .MuiButton-textPrimary {border-radius: 20px; background: #018385; padding: 10px 22px; color: #FFF; text-align: center; font-size: 14px; font-weight: 600; text-transform: none; line-height: normal;}
.ConfirmationPopup .MuiButton-textSecondary {border-radius: 20px; background: #E4E4E4; padding: 10px 22px;  color: #333; text-align: center; font-size: 14px; font-weight: 600; text-transform: none; line-height: normal;}

.ConfirmationPopup .MuiBackdrop-root { background:none; }