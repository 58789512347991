.MyCardContainer {
  background: #fff;
  padding: 0;
  /* min-height: calc(100svh - 142px); */
  width: 100%;
  margin: 8px 8px 8px 0;
  border-radius: 10px;
  padding-top: 10px;

  .FormContainer {
    padding: 16px;
    margin: 0;
  }
}

.MyCardContainerChatArea {
  background: #fff;
  padding: 0;
  height: auto;
  overflow-y: auto;
  overflow-x: auto;
  width: fit-content;
  border-radius: 20px;
}

.MyCardContainer .MuiTypography-h4 {
  color: #000;
  font-size: 14px;
  font-weight: 700;
  line-height: 24px; /* 171.429% */
  letter-spacing: 0.17px;
  margin: 5px;
}
.add_card_btn {
  width: 10%;
  display: grid;
  place-items: center;
}
@media (max-width: 900px) {
  /* .MyCardContainer {
    height: calc(100svh - 74px);
  } */

  .add_card_btn {
    width: 90%;
    margin-top: 2em;
    margin-left: 5%;
  }
  .my_card_container {
    display: list-item;
  }
}
