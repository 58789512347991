
.RaiseDisputeBox{ border-radius: 0 10px 10px 10px; background: #FFF; box-shadow: 0px 4px 4px 0px rgba(0, 0, 0, 0.05); padding: 20px 20px 30px; max-width: 60%;}
.RaiseDisputeBox .MuiTypography-body1{ display: block; color: #414148; font-size: 16px; font-style: normal; font-weight: 600; line-height: normal; margin-bottom: 20px;}
.RaiseDisputeBox .MuiTextField-root{ width: 100%;}

.RaiseDisputeBox .MuiButton-containedPrimary { padding: 10px 45px; border-radius: 20px; background: #018385; color: #FFF; text-align: center; font-size: 14px; font-weight: 600;
    line-height: normal; margin-right: 15px; text-transform: none;}

.UploadCnt{ border-radius: 4px; border: 1px dashed rgba(0, 0, 0, 0.12); padding: 30px; margin-bottom: 30px; text-align: center;}
.UploadCnt .MuiSvgIcon-root{ margin-bottom: 10px; color: #2196F3; width: 100%;}
.UploadCnt a{color: #2196F3;}
.UploadCnt .MuiTypography-body1{ display: block; color: rgba(0, 0, 0, 0.60);  text-align: center; font-size: 12px; font-weight: 400; line-height: 143%;  letter-spacing: 0.17px; margin: 8px 0 0;}
.RaiseDisputeBox .css-i4bv87-MuiSvgIcon-root{padding-left: 25%;}
.RaiseDisputeBox .css-1d1r5q-MuiFormHelperText-root{color:#d32f2f; padding-bottom: 15px;}