.PaymentSummaryBox{ background-color: white;  border-top-right-radius: 10px; border-bottom-left-radius: 10px; border-bottom-right-radius: 10px;
    padding: 0; margin: 10px;   max-width: 330px;}
    .PaymentSummaryBox .MuiTypography-h6{ padding:15px 25px; border-bottom: 1px solid rgba(224, 224, 224, 1); font-size: 16px;
        font-weight: 600;}
    .PaymentSummaryBox .MuiTableCell-body{ padding: 0 25px;}
    
    .PaymentSummaryBox .MuiTableCell-body p { display: flex; justify-content: space-between;}
    
    .lastRowButton td{ border-bottom: 0; padding: 15px 25px !important;}
    
    .applyPromo .MuiFormLabel-root{ top: -8px;}
    .applyPromo .MuiFormLabel-root.Mui-focused{ top: 0px;}
    .applyPromo .MuiOutlinedInput-root{ border-radius: 0; padding: 0;}
    .applyPromo .MuiButton-root{ background-color: #fff; border: 1px solid rgba(0, 0, 0, 0.23);  border-radius: 0; color: #018385; font-size: 14px; font-weight: 700; border-left: 0;}
    .applyPromo .MuiOutlinedInput-input{ padding: 7.5px 14px;}
    
    .PaymentSummaryBox .MuiChip-root{ margin: 0; border-radius: 0; background: #D9D9D9; text-transform: uppercase; font-weight: 600;}