.sidebar {
  width: 32%;
  background: white;
  min-height: calc(100svh - 33px);
  margin: 8px;
  padding-top: 16px;
  border-radius: 10px;
  position: relative;
  transition: all 0.4s ease-in-out;
}

.sidebar.show {
  transition: all 0.4s ease-in-out;
  transform: translateX(0px);
  -webkit-transform: translateX(0px);
  -moz-transform: translateX(0px);
  -ms-transform: translateX(0px);
  -o-transform: translateX(0px);
}
.sidebar.hide {
  transform: translateX(-110%);
  transition: all 0.4s ease-in-out;
}

.sidebar .buyerTab {
  min-width: auto !important;
  padding: 3% !important;
  text-transform: none;
}
.sidebar .sellerTab {
  min-width: 33%;
  padding: 3% !important;
  text-transform: none;
}
.sidebar .MuiTab-root.Mui-selected {
  color: #333333;
  font-weight: 600;
}

.sidebar .MuiTabs-indicator {
  background: #018385;
  height: 5px;
}

.sidebar .MuiTabPanel-root {
  padding: 0px;
  height: calc(100svh - 278px);
  overflow: auto;
  overflow-x: hidden;
}

/* Add this to your CSS */
.active-job {
  background-color: #ebf6f6; /* Set your desired highlight color */
}
.backbutton {
  transform: translateX(25px);
  border-radius: 50%;
  width: 30px;
  display: grid;
  height: 30px;
  border: 1px solid #dddddd;
  background-color: #fff;
}
.size-60 {
  border-radius: 50%;
  height: 60px;
  width: 60px;
}

@media (max-width: 900px) {
  .backbutton {
    display: none;
  }
  .sidebar {
    width: 100%;
    display: flex;
    padding-top: 0;
    min-height: calc(100svh - 60px);
    height: 100vh;
    margin: 0;
    border-radius: 0;
  }
  .sidebar .MuiTabPanel-root {
    height: calc(100svh - 176px);
  }
  .size-60 {
    height: 40px;
    width: 40px;
  }
}
