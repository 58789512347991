

.ProjectDetails .css-uls1yk-MuiDivider-root{ margin-left: 16px;  max-width: 280px; border-color: #eee;}

.ProjectDetails .MuiListItemText-root .MuiListItemText-primary{ font-size: 12px; font-weight: bold; text-transform: uppercase; color: #333;}
.ProjectDetails .MuiListItemText-root .MuiListItemText-secondary{ font-size: 16px; font-weight: normal; color: #5E5F69;}

.ProjectDetails .css-1mcnwpj-MuiList-root{ padding: 0; background: none;}


.ProjectDetails .ProjectDesc{height: calc(100svh - 309px); background: #fff; overflow:auto; overflow-x: hidden;}
.MuiTypography-body1{color: #333;}


.editForm .MuiOutlinedInput-input{ border-radius: 10px; background: #EFEFEF; border-color:#EFEFEF ; color: #5E5F69; font-size: 16px; font-weight: 400;}
.editForm .MuiOutlinedInput-notchedOutline{ border-width: 0;}
.editForm .MuiFormControl-root{ margin-top: 5px;}

@media (max-width:900px) {
    .ProjectDetails .css-uls1yk-MuiDivider-root{
        max-width: calc(100% - 32px);
    }
    .ProjectDetails .MuiListItemText-root .MuiListItemText-secondary{
        font-size: 14px;
    }
}