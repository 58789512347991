
.NotificationsBar{ background: #FFF; box-shadow: -4px 4px 10px 0px rgba(0, 0, 0, 0.05); width: 397px; height: 100svh; position: absolute; top: 0; right: 0;z-index: 10;}

.Notify-Header { color: #333; 
    font-size: 16px; 
    font-weight: 700; 
    line-height: 24px;
    letter-spacing: 0.17px; 
    display: flex; 
    padding:15px 20px; 
    justify-content: space-between; 
    align-items: center; 
    border-bottom: 1px solid #DAEFE4;
}  

.Notify-Header .MuiTypography-body1{ color: #333; display: flex; align-items: center;}
.Notify-Header a{ color: #333; 
    text-align: right; 
    font-size: 12px; 
    font-weight: 400; 
    line-height: 24px;
    letter-spacing: 0.17px; 
    text-decoration-line: underline;}

.Notify-Header button:hover{ 
        color: #333; 
        text-align: right; 
        font-size: 12px; 
        font-weight: 400; 
        line-height: 24px;
        letter-spacing: 0.17px; 
        text-decoration-line: underline;
        
    }
.Notify-Details-Cnt{ height: calc(100svh - 55px); 
    overflow: hidden; 
    overflow-y: auto;}
.Notify-Details{ display: flex; 
    padding:15px 20px 0px 20px; 
    color: #414148; 
    font-size: 16px; 
    font-style: normal; 
    font-weight: 400; 
    line-height: normal; align-items: center; }
.Notify-Details img{ margin-right: 10px; width: 40px; height: 40px;}
.Notify-Details:hover{ background: #EBF6F6;}

.Notify-Details .MuiTypography-subTitle1 a{ color: #000; text-decoration-color: #000;}


.Notify-Details-Cnt button{ color: #333; 
    text-align: left; 
    font-size: 12px; 
    font-weight: 400; 
    line-height: 24px;
    letter-spacing: 0.17px;  width: 100%;
    padding: 0; text-transform: none; text-decoration: none;
    display: block;
    }

.Notify-Footer { color: #333;
    display: none; 
    font-size: 16px; 
    font-weight: 700; 
    line-height: 24px;
    letter-spacing: 0.17px;     
    padding:15px 20px; 
    justify-content: space-between; 
    align-items: center; 
    border-top: 1px solid #DAEFE4;
}  

@media (max-width:900px) {
    .NotificationsBar{ width: 100%; z-index: 1; display: flex; flex-direction: column;}
    .Notify-Details-Cnt { flex-grow: 1; overflow-y: auto; }
    .Notify-Details { font-size: 14px; }
    .Notify-Footer {
        display: flex;
    }
    .Notify-Footer button, .Notify-Footer button.LoginButton{
        border-radius: 20px !important;
        padding: 5px 15px !important;
        box-shadow: none;
    }
}
    .Notify-Time{
        text-align: end;
        padding-right: 3%;
        border-bottom: 1px solid #DAEFE4;
    }
    .NotificationBox:hover{
        background: #EBF6F6;
    }
