
.projectCompletionBox{ background: #fff;  border-radius: 10px 10px 0px 0px; height: 70px; border-top: 1px solid #eee;}
.projectCompletionBox .MuiTypography-body1{ font-size: 16px; color: #333; font-weight: 600; line-height: normal; margin-bottom: 0px;}

/* .projectCompletionBox .MuiButton-textPrimary {padding: 10px 30px; border-radius: 20px; color: #FFF; text-align: center; font-size: 14px; font-weight: 600; line-height: normal; margin-right: 15px; text-transform: none;} */
/* .projectCompletionBox .MuiButton-textPrimary:hover{ background: ;} */
/* .projectCompletionBox .MuiButton-textSecondary {padding: 10px 30px; border-radius: 20px; background: #E4E4E4; color: #333; text-align: center; font-size: 14px; font-weight: 600; line-height: normal; text-transform: none;}
.projectCompletionBox .MuiButton-textSecondary:hover{ background: #fff; } */

.rejectButton{ background: #e4e4e4 !important; color: #333; border-radius: 20px;}
.rejectButton:hover{ background: #fff !important;}

.sellerButton{ background: #EFEFEF !important; color: #333; border-radius: 20px;}