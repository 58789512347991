.contactHeader {
  background: white;
  border-radius: 10px;
  height: 100svh;
}

.contactUsForm {
  background: #fff;
  padding: 16px;
  height: calc(100svh - 195px);
  overflow-y: auto;
}
.contactUsForm .MuiTypography-body1 {
  display: block;
  color: #414148;
  font-size: 16px;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
  margin-bottom: 20px;
}
.contactUsForm .MuiTextField-root {
  width: 50%;
  height: "33px";
}
.contactUsForm .MuiTypography-body2 {
  color: black;
  font-size: 14px;
  padding-bottom: 18px;
}
.textFieldContactForm .css-wb57ya-MuiFormControl-root-MuiTextField-root {
  height: 33px;
}
/* .contactUsForm  .MuiButton-containedPrimary { padding: 10px 45px; border-radius: 20px; background: #018385; color: #FFF; text-align: center; font-size: 14px; font-weight: 600;
  line-height: normal; margin-right: 15px; text-transform: none;} */

.contactUsForm .UploadCnt {
  border-radius: 4px;
  border: 1px dashed rgba(0, 0, 0, 0.12);
  padding: 30px;
  margin-bottom: 30px;
  text-align: center;
  width: 44%;
}
.contactUsForm .UploadCnt .MuiSvgIcon-root {
  margin-bottom: 10px;
  color: #2196f3;
  width: fit-content;
  margin: 0 auto;
}
.contactUsForm .UploadCnt a {
  color: #2196f3;
}
.contactUsForm .UploadCnt .MuiTypography-body1 {
  display: block;
  color: rgba(0, 0, 0, 0.6);
  text-align: center;
  font-size: 12px;
  font-weight: 400;
  line-height: 143%;
  letter-spacing: 0.17px;
  margin: 8px 0 0;
}
.contactUsForm
  .css-1yk1gt9-MuiInputBase-root-MuiOutlinedInput-root-MuiSelect-root {
  width: 50%;
}
/* .contactUsForm .css-i4bv87-MuiSvgIcon-root {
  padding-left: 25%;
} */
.contactUsForm .css-q8hpuo-MuiFormControl-root {
  padding-bottom: 25px;
}

/* .ContactForm { width:100%; min-height: calc(100svh - 92px); position: relative; margin: 8px 0; }

.ContactForm .ContactForm{border-radius:0 0 10px 10px; height: calc(100svh - 192px); max-width: none; overflow-y: auto; padding-right: 40%;}

.ContactForm  .MuiTypography-body2{ font-size: 14px; color: #333; margin-bottom: 30px; margin-top: 20px;}
.ContactForm  .MuiTypography-body2 a{ color: #333;}
.ContactForm  .MuiTypography-body1{ font-size: 14px; margin-bottom: 15px;}

.TitleHeader{ font-size: 20px; font-weight: bold; padding: 20px; background: #fff; border-radius: 10px 10px 0 0; border-bottom: 1px solid #ddd;}

.ContactForm .MuiFormControl-fullWidth{ margin-bottom: 30px;}
.MuiMenu-paper.ContactForm {
    background-color: #333;
  }

.customSelectBox{
    
} */
@media (max-width: 900px) {
  .contactUsForm {
    padding: 16px;
    height: calc(100svh - 100px);
  }
  .contactUsForm .MuiTextField-root,
  .contactUsForm .UploadCnt,
  .contactUsForm
    .css-1yk1gt9-MuiInputBase-root-MuiOutlinedInput-root-MuiSelect-root {
    width: 100%;
    box-sizing: border-box;
  }
}
