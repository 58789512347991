.header {
  background: white;
  height: 60px;
  /* padding: 0 32px; */
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.header > button {
  border-radius: 20px;
}
.heade-logo {
  height: 29px;
}
.size-30 {
  height: 30px;
  width: 30px;
  border-radius: 50%;
}
@media (max-width: 900px) {
  .header {
    background: #f3f6f9;
    /* padding: 0 15px; */
    display: none;
  }
  .heade-logo {
    height: 25px;
  }
  .fab-add-job {
    position: fixed;
    right: 15px;
    bottom: 80px;
    z-index: 1;
  }
}
