.nojobcreated {
  background: white;
  height: calc(100svh - 74px);
  /* width: calc(100% - 428px); */
  margin: 8px 8px 8px 0;
  border-radius: 10px;
  display: flex;
  flex-direction: column;
  align-items: center;
  /* justify-content: center; */
  overflow: auto;
}
.nojobcreated.center {
  justify-content: center;
}

button {
  background-color: transparent;
}

.ChatArea {
  height: calc(100vh - 62px);
  position: relative;
  flex-direction: column;
  transition: all 0.4s ease-in-out !important;
  width: "100%";
  display: inherit;
}

.ChatArea.showSiderBar {
  width: calc(100% - 472px);
}
.ChatArea.hideRightSideBar {
  width: calc(100% - 472px);
}

.copilot-messagearea{
  background-color: #fff !important;
  border-top: solid 1px #d5d5dd ;
}

@media screen and (max-width: 900px) {
  .ChatArea {
    width: 100% !important;
  }
  .chatHeaderInnerContainer {
    width: 100% !important;
  }
  .nojobcreated {
    position: fixed;
    left: 0;
    top: 0;
    width: 100%;
    min-height: 100vh;
  }
}

.Co-PilotText{
    text-transform: none !important;
}

.Co-PilotText::first-letter {
  text-transform: capitalize !important;
}

.co-pilot_icon_img {
  height: 80px;
  width: 80px;
}

@media screen and (max-width: 900px) {
  .co-pilot_icon_img {
    height: 80px;
    width: 80px;
  }
}

.chatheader {
  background: white;
  border-top-left-radius: 10px;
  border-top-right-radius: 10px;
  padding: 8px;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
}

.chatfooter {
  background: white;
  border-bottom-left-radius: 10px;
  border-bottom-right-radius: 10px;
  padding: 8px;
  position: relative;
  bottom: 0;
  width: 100%;
  box-sizing: border-box;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}
.chatfooter .sendButton {
  position: absolute;
  right: 0;
  height: 42px;
  width: 42px;
  border-radius: 30px;
  background-color: #018385 !important;
  min-width: auto;
  box-shadow: none;
}
.chatfooter .sendButton .MuiButton-endIcon {
  margin-left: 0;
}
.chatfooter .MuiOutlinedInput-root input {
  padding: 12px;
}
.chatfooter .MuiOutlinedInput-root {
  background-color: #eff1f6;
  border-radius: 30px;
  width: 100%;
  line-height: 1;
  /* padding: 12.5px 130px 12.5px 14px; */
}
.MessageArea {
  background-color: #eff1f6;
  overflow-y: scroll;
  height: 100%;
  padding: 10px 0;
  position: relative;
  flex-grow: 1;
  width: 100%;
  box-sizing: border-box;
}
.leftMessage span {
  /* background-color: white; */
  border-top-right-radius: 10px;
  border-bottom-left-radius: 10px;
  border-bottom-right-radius: 10px;
  padding: 15px;
  word-break: break-word;
  /* max-width: 70%; */

  display: flex;
  align-items: center;
}

.leftMessage span span {
  padding: 0 5px;
}

.rightMessage span {
  background-color: #eefbff;
  border-top-left-radius: 10px;
  border-bottom-left-radius: 10px;
  border-bottom-right-radius: 10px;
  padding: 15px;
  word-break: break-all;
  max-width: 70%;
}

.rightMessage .rightMessage1 span {
  background-color: #d3d6d6 !important;
  border-top-left-radius: 10px;
  border-bottom-left-radius: 10px;
  border-bottom-right-radius: 10px;
  padding: 15px;
  word-break: break-word;
  max-width: 100%;
}

.rightMessage {
  margin: 10px;
  display: flex;
  justify-content: flex-end;
  flex-direction: column;
  font-size: 14px;
}
.leftMessage {
  margin: 10px;
  display: inline-flex;
  flex-direction: column;
  font-size: 14px;
  background: #fff;
  border-radius: 10px;
  max-width: 60%;
}
.MuiChip-root {
  margin: 5px;
  border-radius: 5px;
  border: 0;
}
.MuiChip-root span {
  background: none;
  border-radius: 0;
  overflow: unset;
}

.MuiChip-root-selected {
  background: #018385;
  color: #fff;
}

.leftMessage .chipGroup {
  padding: 0 15px 15px;
}
.leftMessage .MuiChip-root {
  margin: 0 5px 5px 0;
}

.no-spin-arrows::-webkit-inner-spin-button,
.no-spin-arrows::-webkit-outer-spin-button {
  -webkit-appearance: none;
  appearance: none;
  opacity: 0; /* Hide the arrows */
  pointer-events: none; /* Disable click events on the arrows */
}

.privacyLinks {
  text-decoration: none;
  padding-right: 10px;
  color: #999999;
  font-size: 14px;
}

.right_sidebar_open_btn {
  position: fixed;
  right: 0;
  height: 40px;
  background-color: white;
  border-radius: 15px 0 0 15px;
  -webkit-border-radius: 15px 0 0 15px;
  -moz-border-radius: 15px 0 0 15px;
  -ms-border-radius: 15px 0 0 15px;
  -o-border-radius: 15px 0 0 15px;
  display: none;
  transition: all 0.4s ease-in-out;
}
.right_sidebar_open_btn.show {
  display: block;
}
/* 
.leftMessage {
  background-color: white;
  border-top-right-radius: 10px;
  border-bottom-left-radius: 10px;
  border-bottom-right-radius: 10px;
  padding: 8px;
  width: fit-content;
  max-width: 70%;
  word-break: break-word;
  margin-top: 8px;

  margin-left: 8px;
}
.rightMessage {
  background-color: #eefbff;
  border-top-left-radius: 10px;
  border-bottom-left-radius: 10px;
  border-bottom-right-radius: 10px;
  padding: 8px;
  width: fit-content;
  max-width: 70%;
  word-break: break-word;
  margin-top: 8px;
  margin-left: 8px;
} */

.LoadingBar {
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  z-index: 1;
}
.LoadingBar button {
  width: 100%;
  border: 0 !important;
  background: #eff1f6;
  border-radius: 0;
}
.LoadingBar button .MuiCircularProgress-root {
  width: 60px !important;
  height: 60px !important;
}

.DateDivider {
  margin: 10px;
}
.DateDivider .MuiChip-root {
  border-radius: 10px;
  background: #eefbff;
  box-shadow: 0px 4px 4px 0px rgba(0, 0, 0, 0.03);
  padding: 12px 10px;
  color: #414148;
  font-size: 11px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
}
.DateDivider.css-1puturl-MuiDivider-root::before,
.DateDivider.css-1puturl-MuiDivider-root::after {
  border-color: #ddd;
}

.MessageArea .leftMessage .MuiTypography-body1 {
  padding: 5px 0;
  display: flex;
  align-items: center;
}
.MessageArea .leftMessage .MuiTypography-body1 span {
  color: #9d9ea8;
  font-size: 12px;
  font-weight: 500;
  line-height: normal;
  background: none;
  padding: 0 0 0 8px;
}

.MessageArea .rightMessage .MuiTypography-body1 {
  padding: 5px 0;
  width: 100%;
  text-align: right;
  display: flex;
  justify-content: flex-end;
}
.MessageArea .rightMessage .MuiTypography-body1 span {
  color: #9d9ea8;
  font-size: 12px;
  font-weight: 500;
  line-height: normal;
  background: none;
  padding: 0 0 0 8px;
  text-align: right;
  max-width: 100%;
}
.MessageArea .rightMessage .MuiTypography-p {
  display: flex;
  align-self: flex-end;
  align-items: center;
}
.MessageArea .MuiChip-root span {
  overflow: hidden;
  max-width: none !important;
}
.MessageArea .MuiChip-label {
  max-width: none !important;
}
.MessageArea .MuiChip-root:hover {
  background: #018385;
  color: #fff;
}
/* .chatfooter .attachBtn { position: absolute; right: 95px; min-width: auto; padding: 0; width: 42px; height: 42px;} */
.chatfooter .attachBtn {
  position: relative;
  height: 40px;
  width: 40px;
  min-width: 40px;
  background-color: #eff1f6;
  border-radius: 50%;
  -webkit-border-radius: 50%;
  -moz-border-radius: 50%;
  -ms-border-radius: 50%;
  -o-border-radius: 50%;

  border: none;
}
.attachBtn label,
.attachBtn div {
  cursor: pointer;
  position: absolute;
  top: 0;
  left: 0;
  display: grid;
  place-items: center;
  height: 40px;
  width: 40px;
  min-width: 40px;
  border-radius: 50%;
  -webkit-border-radius: 50%;
  -moz-border-radius: 50%;
  -ms-border-radius: 50%;
  -o-border-radius: 50%;
}
.chatfooter .emojiBtn {
  position: absolute;
  right: 67px;
  min-width: auto;
  padding: 0;
  width: 42px;
  height: 42px;
}
.chatfooter .meetingBtn {
  position: absolute;
  right: 39px;
  min-width: auto;
  padding: 0;
  width: 42px;
  height: 42px;
  color: #9d9ea8;
}

/* .MyCardContainer .MuiButton-root {
  background-color: #018385;
  border-radius: 20px;
  text-transform: none;
  font-weight: 600;
} */

.overflow-y-hidden {
  overflow-y: hidden;
}

.TabContent {
  height: calc(100svh - 242px);
  overflow-y: auto;
}

.dots-5 {
  width: 10px;
  aspect-ratio: 1;
  border-radius: 50%;
  animation: d5 1s infinite linear alternate;
  position: relative;
  left: 50px;
  top: -5px;
  background: red;
}

.actions-container {
  background-color: #eff1f6;
}

.callIcon {
  padding-left: 10% !important;
}
.CreateEvent {
  padding-bottom: 3%;
}

/* .fc-v-event{
  background-color: red;
  border:1px solid red
} */


@keyframes d5 {
  0% {
    box-shadow: 20px 0 #000, -20px 0 #0002;
    background: #000;
  }
  33% {
    box-shadow: 20px 0 #000, -20px 0 #0002;
    background: #0002;
  }
  66% {
    box-shadow: 20px 0 #0002, -20px 0 #000;
    background: #0002;
  }
  100% {
    box-shadow: 20px 0 #0002, -20px 0 #000;
    background: #000;
  }
}
.footer_desktop_btns {
  display: flex !important;
}
.footer_mobile_btns {
  display: none;
}
@media (max-width: 900px) {
  .footer_mobile_btns {
    margin: 0 0.5em;
    display: block;
  }
  .footer_desktop_btns {
    display: none !important;
  }
  .css-xan9zm {
    padding: 10px;
  }
  .css-152mnda-MuiInputBase-input-MuiOutlinedInput-input {
    padding: 10.5px 14px;
  }
  .ChatArea {
    width: 100%;
    height: 100%;
    position: fixed;
    top: 0;
    left: 0;
    background: #fff;
    z-index: 1;
    display: flex;
    flex-direction: column;
  }
  .TabContent {
    height: calc(100svh - 164px);
  }
  .chatheader {
    box-shadow: 0px 4px 10px 0px #00000014;
    position: relative;
    z-index: 1;
  }
  .chatfooter {
    box-shadow: 0px -4px 10px 0px #00000014;
    position: relative;
    z-index: 1;
  }
}
.dots-7 {
  width: 10px;
  aspect-ratio: 1;
  border-radius: 50%;
  animation: d7 1s infinite linear alternate;
  position: relative;
  left: 90%;
  top: -5px;
}
@keyframes d7 {
  0% {
    box-shadow: 20px 0 #000, -20px 0 #0002;
    background: #000;
  }
  33% {
    box-shadow: 20px 0 #000, -20px 0 #0002;
    background: #0002;
  }
  66% {
    box-shadow: 20px 0 #0002, -20px 0 #000;
    background: #0002;
  }
  100% {
    box-shadow: 20px 0 #0002, -20px 0 #000;
    background: #000;
  }
}

.img-icon {
  position: relative;
}

.img-icon-text {
  position: absolute;
  font-size: 10px;
  top: 10px;
  left: 4px;
  color: white;
  font-weight: 700;
}
.rechargeWalletBox {
  background-color: #f0f0f0;
  margin-top: 10px;
  padding: 15px;
  border-radius: 15px;
}

/* !DASHBOARD CHAT CONTAINER STYLES */

@media screen and (max-width: 900px) {
  .chat-main-container.show {
    display: block;
  }
  .chat-main-container.hide {
    display: none;
  }
}


.resourceTable {
  display: flex;
  background-color: #fff;
  color: black;
  justify-content: flex-end;
  align-items: flex-end; /* Aligns text to bottom */
  padding: 10px; /* Adds some padding for a better message-like appearance */
  border-radius: 10px; /* Adds rounded corners */
  max-width: 500px;
  font-size: 14px;
  margin-left: auto; /* Pushes the message to the right */
  margin-top: 10px; /* Adds some space between messages */
  box-shadow: 0px 4px 6px rgba(0, 0, 0, 0.1); /* Adds a subtle shadow for better visual separation */
  margin-right: 10px;
}

.resource-heading {
  font-size: 16px !important;
  font-weight: 700 !important;
}

.resource-typo{
  font-size: 16px !important;
  font-weight: 500 !important;
}

.resource-table-cell {
  font-size: 14px !important;
}