/* .FileCnt{ 
  height: calc(100vh - 120px);
  background: #fff;
  overflow: auto;
  overflow-x: hidden; border-radius: 10px; color: #5E5F69; font-size: 14px;
}
.img-icon{
  position: relative;
  margin-right:10px;
  
}

.img-icon-text{
  position: absolute;
  font-size: 12px;
  top:6px;
  left:4px;
  color:white;
  font-weight: 700;

}

.stack-1{
 flex-direction: row;
}

.stack-2-typo-1{
  font-size: 10px;
  font-weight: 400;
  color:#414148;

}

.stack-2-typo-2{
  font-size: 10px;
}

.stack-3-box{
flex-direction: row;
}

.download-icon{
border:none;
outline: none;
background-color: white;
cursor: pointer;
margin-left: 25px;
}

.vertical-dots{
border:none;
outline: none;
background-color: white;
cursor: pointer;

} */

.FileCnt{ 
  height: calc(100vh - 118px);
  background: #fff;
  overflow: auto;
  overflow-x: hidden; border-radius: 10px; color: #5E5F69; font-size: 14px;
}
.file-item {
  display: flex;
  align-items: center;
  margin: 10px /* Add margin if needed */
}

.FileCnt .file-item .img-icon {
  margin-right: 10px; /* Add margin to separate the icon from text */
  text-decoration: none;
  color: #000; /* Set the color you want for the link */
}

.FileCnt .file-item .img-icon img {
  height: 25px;
  width: 25px;
}

.file-img-text {
  position: absolute;
  font-size: 10px;
  top: 0px;
  left:4px;
  color:white;
  font-weight: 700;
 /* Adjust the margin as needed */
}

.FileText.MuiTypography-body1{
  font-size: small;
}

/* Adjust styles for Typography and Buttons as needed */
