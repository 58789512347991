.StripeElement {
  height: 1.4375em;
  padding: 13px 14px 12px;
  width: 100%;
  color: #32325d;
  background-color: white;
  border-radius: 4px;
  margin-left: 15px;
  border: 1px solid #bbb;
  margin-bottom: 15px;
}

.StripeElement--focus {
  box-shadow: 0 1px 3px 0 #cfd7df;
}

.StripeElement--invalid {
  border-color: #fa755a;
}

.StripeElement--webkit-autofill {
  background-color: #fefde5 !important;
}
