.MyCardListContainer {
    padding: 5px 16px;
    overflow: auto;
  }
  .MyCardList {
    display: flex;
  }
  
  .Mycard {
    width: 352px;
    height: 222px;
    border-radius: 15px;
    background-color: transparent;
    border: 0;
    color: #fff;
    margin-right: 20px;
  }
  
  .Mycard .MuiGrid-container {
    margin: 0;
    width: 100%;
  }
  .Mycard .MuiGrid-container .MuiGrid-item {
    padding-left: 0;
    padding-top: 14px;
  }
  
  .Mycard .MuiTypography-h5 {
    margin-top: 85px;
  }
  .Mycard .name {
    padding-top: 26px !important;
  }
  
  .MuiTypography-body2 {
    color: #fff;
    font-size: 10px;
    font-weight: 300;
  }
  .MuiTypography-body1 {
    color: #fff ;
    font-size: 14px;
    font-weight: 500;
  }
  .Mycard .name .MuiTypography-body1 {
    color: #fff;
    font-size: 16px;
    font-weight: 500;
  }

  .chooseAnother{
    text-decoration: underline;
    color: black;
    font-weight: bold;
  }

  .payWithWalletButton{
    border: 1px solid #DDDDDD;
    background-color: #eee;
    border-radius: 30px;
    padding: 0px 0px 0px 0px;
  }