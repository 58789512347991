.ActionButtonsCnt{ padding: 10px; text-align: end; background: #fff; border-radius: 0 0 10px 10px;}
.ActionButtonsCnt a{ font-size: 12px; font-weight: 600; text-decoration: underline; color: #5E5F69; text-transform: uppercase; margin: 0 5px;}

.ActionButtonsCnt .css-0 { padding:0;}
.ActionButtonsCnt button { background-color: #018385 ; border-radius: 20px; min-width: 200px; text-transform: none; font-weight: 600;}

.AcceptRejectButtons{  display: flex; justify-content: center;}
.AcceptRejectButtons button{ padding: 7px 30px; margin: 5px;}

.rejectButton{ background: #e4e4e4 !important; color: #333;}
.rejectButton:hover{ background: #fff !important;}