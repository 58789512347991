.contactCnt { background: white; border-radius: 10px; min-height: 100svh; display: flex; flex-direction: column;}

.ContactFormContainer{ display: flex; flex-grow: 1;}

.ContactLeft{ width: 25%; padding: 30px; background: rgb(251,252,219);  background: linear-gradient(102deg, rgba(251,252,219,1) 0%, rgba(222,243,250,1) 100%); position: relative;}
.ContactLeft .MuiTypography-body1{ font-size: 60px; font-family: 'Bai Jamjuree', sans-serif !important;  font-weight: 700; margin-top: 15%;}
.contactImg{ position: absolute; bottom: 10%;}

.ContactRight{width: 75%; padding: 30px; display: flex; justify-content: center; align-items: center;}

.ContactForm{ width: 480px !important; padding: 0 !important; }

.ContactRight .ContactForm .MuiTypography-body1{ font-size: 24px; color: #202030; font-weight: 700;}
.ContactRight .ContactForm .MuiTextField-root { margin-bottom: 0px;}
.ContactRight .ContactForm .selectList {padding-bottom: 20px;}

.ContactRight .ContactForm .MuiOutlinedInput-root{ background: #F4F4F4;}


.ContactRight .UploadCnt{ border-radius: 4px; border: 1px dashed rgba(0, 0, 0, 0.12); padding: 30px; margin-bottom: 30px; text-align: center; margin-top: 10px; }
.ContactRight .UploadCnt .MuiSvgIcon-root{ margin-bottom: 10px; color: #2196F3; width: 100%;}
.ContactRight .UploadCnt a{color: #2196F3;}
.ContactRight .UploadCnt .MuiTypography-body1{ display: block; color: rgba(0, 0, 0, 0.60);  text-align: center; font-size: 12px; font-weight: 400; line-height: 143%;  letter-spacing: 0.17px; margin: 8px 0 0;}
.ContactRight .MuiButton-containedPrimary { padding: 10px 45px; border-radius: 20px; background: #018385; color: #FFF; text-align: center; font-size: 14px; font-weight: 600;
    line-height: normal; margin-right: 15px; text-transform: none;}

 .contactHome {width: 100% !important;} 
.YemoFooter{ position: fixed; bottom: 0; width: calc(100% - 60px); left: 0; padding:15px 30px; font-size: 14px; border-top: 1px solid #EEEEEE; color: #999; background: none; display: flex; justify-content: space-between;}
.YemoFooter.contact{ background: #fff; position: relative;}
.footerLinks a{ font-size: 14px; color: #999999; text-decoration: none; padding-left: 30px;}
.footerLinks a:first-child{ padding-left: 0;}

@media (max-width:1600px){    
    .ContactLeft{
        width: 32%;
    }
    .ContactRight{
        width: 68%;
    }
}

@media (max-width:1200px){
    .ContactLeft .MuiTypography-body1 { font-size: 50px; }
    .ContactLeft{
        width: 45%;
    }
    .ContactRight{
        width: 55%;
    }
}

@media (max-width:900px) {
    .ContactLeft .MuiTypography-body1 { font-size: 30px; }
    .ContactRight .ContactForm .MuiTypography-body1 { font-size: 20px; }
    .ContactForm {
        width: 100% !important;
    }
    .ContactFormContainer {
        flex-direction: column;
    }
    .ContactLeft { 
        width: 100%;
        box-sizing: border-box;
        background: none;
        padding-bottom: 0;


     }
     .ContactLeft .MuiTypography-body1{
        margin-top: 25px;
    }
    .ContactRight {
        width: 100%;
        box-sizing: border-box;
    }
    .d-md-none{
        display: none;
    }
    .ContactRight .UploadCnt .MuiTypography-body1{ font-size: 12px;}
  }