.typography-h2{
text-align: center;
font-size: 24px;
font-weight: 700;
}

.typography-h3{
text-align: center;
font-size: 16px;
font-weight: 500;
}

.typography-p{
text-align: center;
font-size: 16px;
font-weight: 100;

}

.stack-container{
display: flex;
flex-direction: row;
justify-content: space-between;
align-items: center;
padding: 20px;
}

.google-calendar{
    margin-right: 20px;
}

.divider{
    width:100%
    /* :#DDDDDD; */
}

.stack-2{
    margin-top: 30px;
    display: flex;
    flex-direction: row;
    justify-content: space-between;

}

.btn-next{
    border: none;
    border-radius: 20px;
    padding:5px 23px;
    color:#333333;
    background-color: #E4E4E4;
    font-weight: 700;
    font-weight: 12px;

}

.btn-skip{
    color:#5E5F69;
}

