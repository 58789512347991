@import url("https://fonts.googleapis.com/css2?family=Inter&family=Montserrat:wght@400;500;600;700&display=swap");


@import url('https://fonts.googleapis.com/css2?family=Bai+Jamjuree:wght@600;700&family=Inter:wght@400;500;600;700&family=Nunito+Sans:opsz,wght@6..12,400;6..12,700&display=swap');

* {
  font-family: Inter !important;
}

.home {
  text-align: center;
  height: 100%;
  width: 100%;
  position: absolute;
  margin: 2% 1% 1% 3%;
}

.home-box {
  width: 115%;
  height: 53%;
  flex-shrink: 0;
  background-color: #f5f5f5;
  border-radius: 26px;
  padding: 10%;
  position: relative;
  margin: 16% 0% 0% -265%;
}

.homepageimage {
  height: 133%;
  width: 77%;
  margin: -17% 44% 0% 18%;
  position: relative;
}

.home-logo {
  margin: 3% 4% -15% -29%;
}

.home-content {
  text-align: center;
  font-size: 25px;
  font-weight: 400;
}

.welcome {
  color: "#0F485B";
  color: #0f485b;
  text-align: center;
  font-size: 48px;
  font-weight: 900;
}

.secoendline {
  color: #000;
  font-size: 30px;
  font-weight: 600;
}

.outlinebutton {
  color: #0f485b;
  border: 4px solid #0f485b;
  border-radius: 35px;
  font-size: 20px;
  font-weight: 400;
  padding: 1% 4%;
  text-transform: none;
}

.outlinebutton:hover {
  color: #0f485b;
  border: 4px solid #0f485b;
  font-size: 20px;
  font-weight: 400;
}

.fillbutton {
  background-color: #0f485b;
  border-radius: 35px;
  font-size: 20px;
  font-weight: 400;
  padding: 1.2% 4.5%;
  text-transform: none;
}

.fillbutton:hover {
  background-color: #0f485b;
}

.contentBox {
  padding: 5.88% 2.75% 2.88% 2.75%;
  border-radius: 1.625rem;
  width: 30.25%;
  background-color: #f5f5f5;
  margin: 14% 0% 6.12% 9.62%;
  position: absolute;
}

.Yemo-image {
  margin: -58% 0% 0% 23%;
  width: 37%;
  position: absolute;
}

.submitebutton {
  background-color: #0f485b;
  font-size: 20px;
  font-weight: 400;
  border-radius: 35px;
  padding: 1% 7%;
  margin: 1% 0% 1% 41%;
  text-transform: none;
}

.submitebutton:hover {
  background-color: transparent;
  border: 2px solid #0f485b;
  color: #0f485b;
}

.linkedbutton {
  text-transform: none;
  border-radius: 30px;
  padding: 1% 4%;
  margin: 5% 0% 0% 38%;
}

.title {
  font-weight: 700;
  text-align: center;
}

.lable {
  margin-bottom: 1%;
  font-weight: 400;
  font-size: 1.125rem;
}

.yemolink {
  color: #0f485b;
  text-decoration: none;
}

.startline {
  width: 40%;
  margin: 3% 0% 0% 4%;
  border: 0.25px solid black;
}

.endline {
  width: 40%;
  margin: 0.3% 0% 0% 61%;
  border: 0.25px solid black;
}

.otpinput {
  width: 60px;
  height: 60px;
  font-size: 20px;
  text-align: center;
  margin-right: 10px;
}

.textfillborder {
  border: 1px solid black;
  border-radius: 10px;
}

.css-nxo287-MuiInputBase-input-MuiOutlinedInput-input {
  border-radius: 10px;
}

.css-o9k5xi-MuiInputBase-root-MuiOutlinedInput-root {
  border: 1px solid black;
  border-radius: 10px;
}

.css-s20sh7 > * {
  margin: 0;
}

.submitbutton {
  width: 40%;
  background-color: #0f485b;
  font-size: 20px;
  font-weight: 400;
  border-radius: 35px;
  text-transform: none;
  text-align: center;
}
.LoginPage {
  background: url("../Assets/LoginBg.png");
  background-repeat: no-repeat;
  min-height: 100svh;
  background-size: cover;
}
.LoginButton {
  background-color: #018385;
  box-shadow: none; padding: 8px 22px  !important; border-radius: 10px !important; font-size: 15px !important;
  font-weight: 600; text-transform: none; border-radius: 20px !important;
}

.LoginButton:hover{
  background: #00A4A7;
}

.SocialLoginButton{ padding: 8px 22px  !important; border-radius: 10px !important; font-size: 16px !important;
  font-weight: 700;  text-transform: none;}

.Mui-focused.MuiOutlinedInput-root .MuiOutlinedInput-notchedOutline { border-color: #333;}
.MuiOutlinedInput-input:focus .css-yjsfm1>span{ color: #333;}

.MuiInputLabel-root{ color: #333;}

.img-fluid {
  max-width: 100%;
  height: auto;
}

.font-24 { font-size: 24px; }
.font-80 { font-size: 80px; }

@media (max-width: 1536px) {
  .font-80 { font-size: 60px; }
}

@media (max-width: 1200px) {
  .font-24 { font-size: 22px; } 
  .font-80 { font-size: 50px; }
}

@media (max-width: 900px) {
  .font-24 { font-size: 20px; }
}

@media (max-width: 600px) {
  .font-24 { font-size: 18px; }
  .font-80 { font-size: 45px; }
}

