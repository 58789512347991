body {
  background: #f3f5fb;
  margin: 0 !important;
}
.MainComponent {
  display: flex;
  height: calc(100svh - 60px);
}
*::-webkit-scrollbar {
  width: 6px;
  height: 6px;
  border-radius: 20px;
}

*::-webkit-scrollbar-track {
  background: #e4e5e9;
}

*::-webkit-scrollbar-thumb {
  background: #9ec2ce;
}

*::-webkit-scrollbar-thumb:hover {
  background: #9ec2ce;
}

*::-webkit-scrollbar-button {
  display: none;
}
.MuiInputLabel-root {
  color: black !important;
}

input::-ms-reveal,
input::-ms-clear {
  display: none;
}

/* !STYLE RESET */
button {
  cursor: pointer;
  border: none;
  outline: none;
}
