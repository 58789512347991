
.FormContainer {
    background-color: white;
    border-top-right-radius: 10px;
    border-bottom-left-radius: 10px;
    border-bottom-right-radius: 10px;
    padding: 30px ;
    margin: 10px; max-width: 60%;
}

.FormContainer p{
    color: #414148;
    font-size: 16px;
    font-style: normal;
    font-weight: 600;
    line-height: normal; margin-bottom: 15px;
}

.FormContainer .MuiFormControl-root{ margin: 10px 0 15px;}
/* .FormContainer .MuiButton-root{ background-color: #018385; border-radius: 20px; text-transform: none;  font-weight: 600;} */
.FormContainer .MuiInputBase-input{ padding: 13px 14px;}
.FormContainer .MuiFormHelperText-root{ font-weight: 400; font-size: 12px; }

.FormContainer .MuiFormHelperText-root{color:#d32f2f}