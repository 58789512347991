
.main-container {
    background: #f9faff;
    height: 100svh;
    width: 100vw;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}

.sub-container-1 {
    font-size: 24px;
    color: #444444;
    font-weight: 500;
    text-align: start;
    margin-bottom: 10px;
}

.sub-container-2 {
    font-size: 16px;
    color: #424242;
    font-weight: 400;

}

.sub-container-2_1 {
    margin-bottom: 10px;
}

.li-el-1 {
    margin-top: 10px;
}


.sub-container-3 {
    font-size: 14px;
    padding: 10px 22px;
    border-radius: 20px;
    border: none;
    background-color: #E7E8EA;
    color: #666666;
    cursor: pointer;
    font-weight: 600;
    margin-top: 20px;
}

.just-test {
    padding: 0px 30px;
}