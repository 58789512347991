
.InfoAlert{ padding: 10px;}

.InfoAlert .MuiAlert-message{ padding: 0; display: flex; align-items: center;}
.InfoAlert .MuiAlert-root{font-size: 12px; font-weight: 600; margin: 0; padding: 4px 5px; justify-content: left; margin: 5px 0; }
.InfoAlert .MuiAlert-icon{ display: none;}

.InfoAlert .MuiAlert-standardSuccess{ border-radius: 5px; background: #E0FFF2;   color: #1EA35B; text-align: left;}
.InfoAlert .MuiAlert-standardError{ border-radius: 5px; background: #FFECEC;   color: #E36749; text-align: left;}
.InfoAlert .MuiAlert-standardWarning{border-radius: 5px; background:#FAE6A0;   color: #9D9EA8; text-align: left; border: 0;}
.InfoAlert .MuiAlert-standardInfo{border-radius: 5px; background: #EFF1F6;   color: #9D9EA8; text-align: left; border: 0;}
@media (max-width:900px) {
    .InfoAlert{ padding: 0px;}
    .InfoAlert .MuiAlert-root{margin: 2px 0 0 0; }
}