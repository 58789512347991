.MeetingRecording{border-radius: 10px; background: #EEFBFF; max-width: 264px; margin: 10px; padding: 20px;   }
.MeetingRecording .MuiAvatar-circular{ background: #C6E8E9; margin-right: 10px;}
.rightMessage .MeetingRecording  .LinkTxt span{ display: block; font-size: 14px; font-weight: 400; background-color: #EEFBFF;}
.leftMessage .MeetingRecording  .LinkTxt span{ display: block; font-size: 14px; font-weight: 400; background-color: #EEFBFF;}
.MessageArea .rightMessage .MeetingRecording .MuiTypography-p {
    padding: 0; align-self: center;
  }
.leftMessage .MeetingRecording .LinkTxt {
  display: flex;
  align-items: center;
  color: #414148;;
  font-size: 16px;
  font-style: normal;
  font-weight: 600;
  line-height: normal; 

}
.rightMessage .MeetingRecording .LinkTxt {
  display: flex;
  align-items: center;
  color: #414148;;
  font-size: 16px;
  font-style: normal;
  font-weight: 600;
  line-height: normal; 

}
.MessageArea .leftMessage  .MeetingRecording .MuiTypography-p { border-radius: 0; background: none; padding: 0;}
.MeetingRecording .MeetingLinkTxt{ display: flex; align-items: center; color: #414148; font-size: 16px; font-style: normal; font-weight: 600; line-height: normal;}