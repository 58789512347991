
.AppAlert{ padding: 10px;}

.MuiAlert-root{
    font-size: 14px;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
    display: flex; justify-content: center; margin: 8px 0; padding: 2px 16px;
}

.MuiAlert-standardSuccess{
    border-radius: 10px;
    background: #DAEFE4;
    border: 1px solid #DAEFE4;
    box-shadow: 0px 4px 4px 0px rgba(0, 0, 0, 0.01);
    color: #1EA35B;
}

.MuiAlert-standardWarning{
    border-radius: 10px;
    border: 1px solid #FAE6A0;
    background: #FFF2C2;
    box-shadow: 0px 4px 4px 0px rgba(0, 0, 0, 0.01);
}