.bell-text{
    font-size: 15px;
    font-weight: 400;
    text-align: center;
    color:#999999;
}

.notification-bell{
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    margin-top: 75%;
   
   
}