.RejectAlerts {
  position: fixed;
  transform: translateX(-50%);
  bottom: 100px;
  left: 53%;
  width: min(500px, 80%);
  border-radius: 10px;
  background: #ebf6f6;
  box-shadow: 0px 4px 20px 0px rgba(0, 0, 0, 0.13);
  padding: 20px;
}

@media  screen and (max-width:900px) {
    .RejectAlerts{
        width: 80%;
    }
}

.RejectAlerts .MuiTypography-body1 {
  color: #333;
  font-size: 14px;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
}
.RejectAlerts a {
  margin-top: -30px;
}

.RejectAlerts .MuiStack-root {
  display: flex;
  justify-content: flex-end;
}
.RejectAlerts .MuiButton-btn-primary {
  border-radius: 20px;
  background: #018385;
  padding: 5px 15px;
  text-transform: none;
  color: #fff;
}
.RejectAlerts .MuiButton-btn-secondary {
  border-radius: 20px;
  background: #e4e4e4;
  padding: 5px 15px;
  text-transform: none;
}
