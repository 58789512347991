.sidebar .MuiList-root {
  max-width: 100%;
  padding-top: 0;
  padding-bottom: 0;
}
.sidebar .MuiList-root .MuiDivider-root {
  margin-left: 0;
  border-color: #eee;
}
.sidebar .MuiList-root .MuiListItem-root {
  padding: 0;
}
.sidebar .MuiAvatar-root {
  width: 50px;
  height: 50px;
  margin-right: 10px;
  font-size: 18px;
  font-weight: bold;
}

.sidebar .MuiListItemAvatar-root {
  position: relative;
}
.sidebar .MuiListItemAvatar-root .small-avtar {
  position: absolute;
  right: 10px;
  bottom: -5px;
}

.sidebar .MuiList-root .MuiListItemButton-root {
  padding-left: 12px;
  padding-right: 12px;
}
.sidebar .MuiList-root .MuiListItemButton-root:hover {
  background: #ebf6f6;
}
.sidebar .MuiListItemText-root .MuiListItemText-primary {
  text-align: right;
  color: #9d9ea8;
  font-size: 12px;
  float: right;
}

.sidebar .MuiListItemText-root .MuiTypography-root.chip {
  border-radius: 10px;
  padding: 2px 8px;
  font-size: 12px;
  color: #fff;
}
.sidebar .MuiListItemText-root .MuiTypography-root.title {
  display: block;
  font-size: 16px;
  font-weight: bold;
  color: #333;
  text-align: left;
}
.sidebar .MuiListItemText-root .MuiTypography-root.desc {
  font-size: 14px;
  color: #6a7477;
}
.sidebar .MuiListItemText-root .MuiTypography-root.badge {
  background: #a4a4a4;
  width: 20px;
  height: 20px;
  border-radius: 20px;
  font-size: 11px;
  font-weight: bold;
  color: #fff;
  display: inline-block;
  text-align: center;
  line-height: 20px;
  float: right;
}

/* .drafts .MuiAvatar-root{ background: #C8CBFE;} */
/* .inprogress .MuiAvatar-root{ background: #C9F0BB;}
.completed .MuiAvatar-root{ background: #F9EF99;} */

.drafts .MuiTypography-root.chip {
  background: #555;
}
.inprogress .MuiTypography-root.chip {
  background: #0171df;
}
.completed .MuiTypography-root.chip {
  background: #27cc87;
}
.refund.MuiTypography-root.chip {
  background: #199d74;
}

.MuiTabs-flexContainer {
  overflow-x: auto;
}

/* Hide scrollbar for WebKit browsers (Chrome, Safari) */
.MuiTabs-flexContainer::-webkit-scrollbar {
  display: none;
}

/* Hide scrollbar for Firefox */
.MuiTabs-flexContainer {
  scrollbar-width: none;
}
